import { defaultInfo } from '@/typings/allType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface stateObj {
    currentUserId: string;
    deviceType: string;
    userInfo: defaultInfo;
    profileInfo: defaultInfo;
    mainLoading: boolean;
    userStatus: number;
}
const initialState: stateObj = {
    currentUserId: '',
    deviceType: '',
    userInfo: {},
    profileInfo: {
        subscriptionId: '3',
    },
    mainLoading: false,
    userStatus: 1,
};

export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setCurrentUserId: (state, action: PayloadAction<string>) => {
            state.currentUserId = action.payload;
        },
        setDeviceType: (state, action: PayloadAction<string>) => {
            state.deviceType = action.payload;
        },
        setUserInfo: (state, action: PayloadAction<defaultInfo>) => {
            state.userInfo = action.payload;
        },
        setMainLoading: (state, action: PayloadAction<boolean>) => {
            state.mainLoading = action.payload;
        },
        setProfileInfo: (state, action: PayloadAction<defaultInfo>) => {
            state.profileInfo = action.payload;
        },
        setUserStatus: (state, action: PayloadAction<number>) => {
            state.userStatus = action.payload;
        },
    },
});

export const { setDeviceType, setUserInfo, setProfileInfo, setMainLoading, setUserStatus } = stateSlice.actions;

export default stateSlice.reducer;
