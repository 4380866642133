import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import SignImg from '@/assets/images/website/sign-img.png';
import Logo from '@/assets/images/website/logo.png';
import { useSelector } from 'react-redux';
import Club1 from '@/assets/images/website/1.svg';
import Club2 from '@/assets/images/website/2.svg';
import Club3 from '@/assets/images/website/3.svg';
import Club4 from '@/assets/images/website/4.svg';
import Club5 from '@/assets/images/website/5.svg';
import Club6 from '@/assets/images/website/6.svg';
import Club7 from '@/assets/images/website/7.svg';
const clubLists = [Club1, Club2, Club3, Club4, Club5, Club6, Club7];
const JoinSuccess = (props: any) => {
    const navigate = useNavigate();
    const { deviceType } = useSelector((state: any) => state.state);
    return (
        <div className="r-left login-main">
            <div className="login-box">
                <img src={Logo} className="logo_img" alt="" onClick={() => navigate('/webSite')} />
                <div className="login-form ptJoin">
                    <h1>
                        JOIN THE BETA <br />
                        WAITLIST
                    </h1>
                    <p className="formTop_tip">
                        Thanks for your interest!
                        <br />
                        We'll be in touch soon.
                    </p>
                </div>
                <div className="top-clubsBox">
                    <div className="club_tip">Trusted by clubs at</div>
                    <div className="r-center">
                        {clubLists.map((item, i) => {
                            return (
                                <div
                                    className="clubImg_box"
                                    key={i}
                                    style={{ display: i > 3 && deviceType !== 'pc' ? 'none' : 'block' }}
                                >
                                    <img src={item} className="clubs_img" alt="" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <img src={SignImg} className="SignImg-right" alt="" />
        </div>
    );
};
export default JoinSuccess;
