import React, { useState } from 'react';
import './index.scss';
import LinkedIn from '@/assets/images/website/LinkedIn.svg';
import instagram from '@/assets/images/website/instagram.svg';
import { useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import useLoading from '@/hook/useLoading';
import api from '@/api/login';
function HomeFooter(props) {
    const thisYear = window.utils.getCurremtYear();
    const { deviceType } = useSelector((state: any) => state.state);
    const { setLoadingStatus } = useLoading();
    const [state, setState] = useState({
        email: '',
    });
    const vaildeEmail = () => {
        if (!state.email || !window.utils.isEmail(state.email)) {
            window.format.alert('error', 'Please enter a valid email address');
            return false;
        }
        return true;
    };
    const nextStep = () => {
        if (!vaildeEmail()) return;
        setLoadingStatus(true);
        const pat = {
            email: state.email,
        };
        api.userSubscribe(pat, res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                setLoadingStatus(false);
                setState({ email: '' });
                window.format.alert('success', 'Subscribed successfully');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <section className="footer-box">
            {props.showTopLine ? <div className="footer-lineBox"></div> : ''}
            <div className="r-left footer-opView">
                <div className="footer-logoBox">
                    <img
                        src={require('../../../assets/images/website/footer-logo.png')}
                        className="footer-img"
                        alt=""
                    />
                    <p>Design the apparel of the future</p>
                    <div className="r-left otherLink_box">
                        <a
                            href="https://www.linkedin.com/company/nardo-experience/"
                            target="_blank"
                            className="otherLink_icon"
                            rel="noreferrer"
                        >
                            <img src={LinkedIn} alt="" className="linin_icon" />
                        </a>
                        <a
                            href="https://www.instagram.com/nardo.exp/"
                            target="_blank"
                            className="otherLink_icon"
                            rel="noreferrer"
                        >
                            <img src={instagram} alt="" className="ins_icon" />
                        </a>
                    </div>
                </div>
                {deviceType === 'pc' ? (
                    <>
                        <div className="sub_inputBox">
                            <div className="sub_tip">Stay up to date</div>
                            <div className="r-left">
                                <Input
                                    placeholder="Enter your email"
                                    value={state.email}
                                    onInput={(e: any) => setState({ ...state, email: e.target.value })}
                                    className="r-input sub_input"
                                />
                                <Button className="r-primary_btn r-center sub_btn" onClick={() => nextStep()}>
                                    {' '}
                                    SUBSCRIBE
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="sub_inputBox">
                            <div className="sub_tip">Stay up to date</div>
                            <div>
                                <Input
                                    placeholder="Enter your email"
                                    className="r-input sub_input"
                                    value={state.email}
                                    onInput={(e: any) => setState({ ...state, email: e.target.value })}
                                />
                                <Button className="r-primary_btn r-center sub_btn" onClick={() => nextStep()}>
                                    SUBSCRIBE
                                </Button>
                            </div>
                        </div>
                        {/* <div className="r-left footer_linkBox">
                            <div className="linkBox">
                                <div className="linkTopItem">PRODUCT</div>
                                <div className="linkItem">Overview</div>
                                <div className="linkItem">How it works</div>
                            </div>
                            <div className="linkBox">
                                <div className="linkTopItem">RESOURCES</div>
                                <div className="linkItem">Help</div>
                                <div className="linkItem">Returns</div>
                                <div className="linkItem">Support</div>
                            </div>
                        </div> */}
                    </>
                )}
            </div>
            <div className="footer-lineBox"></div>
            {deviceType === 'pc' ? (
                <div className="footer-copyRight">
                    <div className="contact_box">
                        Need help? Contact our support team on
                        <a className="emailLink_box" href="mailto:leo@nardo-exp.com">
                            leo@nardo-exp.com{' '}
                        </a>
                    </div>
                    <div className="r-left ft_btnBox">
                        <span>© {thisYear ? thisYear : '2024'} Nardo All rights reserved.</span>
                        <div className="r-left marLeft copy_linkViewBox">
                            <a
                                href={`${process.env.REACT_APP_WEB_URI}/terms`}
                                className="copy_linkView"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms
                            </a>
                            <a
                                href={`${process.env.REACT_APP_WEB_URI}/policy`}
                                className="copy_linkView"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="footer-copyRight">
                    <div className="r-left ft_btnBox">
                        <span>© {thisYear ? thisYear : '2024'} Nardo All rights reserved.</span>
                        <div className="contact_box" style={{ marginBottom: 16 }}>
                            Need help? Contact our support team on <br></br>
                            <a className="emailLink_box" href="mailto:leo@nardo-exp.com">
                                leo@nardo-exp.com{' '}
                            </a>
                        </div>
                        <div className="r-left marLeft copy_linkViewBox">
                            <a
                                href={`${process.env.REACT_APP_WEB_URI}/terms`}
                                className="copy_linkView"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms
                            </a>
                            <a
                                href={`${process.env.REACT_APP_WEB_URI}/policy`}
                                className="copy_linkView"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

export default HomeFooter;
