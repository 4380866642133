import axios from '../utils/index';
import { Pat } from '@/typings/request';
const loginApi = {
    verifySubscribe(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/nardo-club/user/verify-subscribe?email=${data.email}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    userOtherSubscribe(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/nardo-club/user-audit/application-for-registration`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    userSubscribe(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/nardo-club/user/subscribe`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
