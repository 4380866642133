import dayjs from 'dayjs';
/* eslint-disable */
import { message } from 'antd';

export default {
    alert(type: any, msg: string, code?: number) {
        if (!msg || code === 30002) return;
        message[type](msg);
    },
    isNumber(value: any): boolean {
        const reg = /^[0-9]*$/;
        return reg.test(value);
    },
    removeFormatMoney(s: string) {
        return parseFloat(s.replace(/[^\d\.-]/g, ''));
    },
    formatMoney(s: string, type?: number) {
        if (/[^0-9\.]/.test(s)) return '0.00';
        if (s == null || s == 'null' || s == '') return '0.00';
        s = s.toString().replace(/^(\d*)$/, '$1.');
        s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
        s = s.replace('.', ',');
        var re = /(\d)(\d{3},)/;
        while (re.test(s)) s = s.replace(re, '$1,$2');
        s = s.replace(/,(\d\d)$/, '.$1');
        if (type == 0) {
            var a = s.split('.');
            if (a[1] == '00') {
                s = a[0];
            }
        }
        return s;
    },
    isEmail(str: string) {
        const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return reg.test(str);
    },
    isWebSite(str: string) {
        const reg =
            /^\b(((https?|ftp):\/\/)?[-a-z0-9]+(\.[-a-z0-9]+)*\.(?:com|co|io|edu|gov|int|mil|net|org|biz|info|name|museum|asia|coop|aero|[a-z][a-z]|((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|\d))\b(\/[-a-z0-9_:\@&?=+,#.!\/~%\$]*)?)$/i;
        return reg.test(str);
    },
    isPassword(str: string) {
        const len: number = str ? str.split('').length : 0;
        return len >= 8 && len <= 16;
    },
    isStartsWith04(number) {
        const strNumber = number.toString();
        return strNumber.startsWith('0');
    },
    isBlank(str: string): boolean {
        return str == null || false || str === '' || str.trim() === '' || str.toLocaleLowerCase().trim() === 'null';
    },
    getPageQuery(url: string) {
        const regex = /from=([^&#]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            const fromValue = match[1];
            return decodeURIComponent(fromValue);
        }
        return '';
    },
    debounce(fnName: any, time: number) {
        let timeout: any = null;
        return function () {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                fnName();
            }, time);
        };
    },
    timeFormatYear(val: any) {
        let date: any = new Date(val * 1000);
        if (!date) {
            date = Date.now();
        }
        const time = date.toLocaleDateString().replace(/\//g, '/');
        return time;
    },
    formatNumber(s: string | null): string | undefined {
        if (!s) {
            return '';
        }
        if (s.length == 11) {
            return s.slice(0, 3) + ' ' + s.slice(3, 7) + ' ' + s.slice(7, 11);
        } else if (s.length == 8) {
            return s.slice(0, 4) + ' ' + s.slice(4, 8);
        } else if (s.length == 9) {
            return s.slice(0, 3) + ' ' + s.slice(3, 6) + ' ' + s.slice(6, 9);
        } else if (s.length == 10) {
            return s.slice(0, 4) + ' ' + s.slice(4, 7) + ' ' + s.slice(7, 10);
        } else {
            return s;
        }
    },
    getCurremtYear(): number {
        const year = dayjs().year();
        return year;
    },
    foramtTimeDMY(date: any) {
        date = date ? Number(date) : new Date();
        return dayjs(date).format('DD/MM/YYYY');
    },
    isExpired(dateNum: number) {
        const nowDate = Date.now();
        return nowDate - dateNum > 0;
    },
    validateInput(input) {
        if (!input.toString()) {
            return '';
        }
        input = input
            .replace(/[^\d.]/g, '')
            .replace(/^\./g, '')
            .replace(/\.{2,}/g, '')
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        return input;
    },
};
