import { useRef, useEffect } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { setMainLoading } from '@/store/stateSlice';
import { useSelector } from 'react-redux';
function useLoading() {
    const dispatch = useAppDispatch();
    const { mainLoading } = useSelector((state: any) => state.state);

    const setLoadingStatus = (val: boolean) => {
        dispatch(setMainLoading(val));
    };

    return { setLoadingStatus, mainLoading };
}
export default useLoading;
