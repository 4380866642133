import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Modal } from 'antd';
import api from '@/api/login';
import './index.scss';
import SignImg from '@/assets/images/website/sign-img.png';
import Logo from '@/assets/images/website/logo.png';
import { useAppDispatch } from '@/store/hooks';
import { setUserInfo } from '@/store/stateSlice';
import { useSelector } from 'react-redux';
import useLoading from '@/hook/useLoading';
const clubLists = [
    require('@/assets/images/website/club1.png'),
    require('@/assets/images/website/club2.png'),
    require('@/assets/images/website/club3.png'),
    require('@/assets/images/website/club4.png'),
    require('@/assets/images/website/club5.png'),
    require('@/assets/images/website/club6.png'),
    require('@/assets/images/website/club7.png'),
];
const SignUp = (props: any) => {
    const { setLoadingStatus } = useLoading();
    const navigate = useNavigate();
    const { deviceType } = useSelector((state: any) => state.state);
    const [isError, setIsError] = useState(false);
    const [placeholderText, setPlaceholderText] = useState('Email address');
    const [state, setState] = useState({
        email: '',
    });
    const dispatch = useAppDispatch();
    const { userInfo } = useSelector((state: any) => state.state);
    const vaildeEmail = () => {
        if (!state.email || !window.utils.isEmail(state.email)) {
            setPlaceholderText('Please enter a valid email address');
            // setState({ email: '' });
            setIsError(true);
            return false;
        }
        setPlaceholderText('Email address');
        setIsError(false);
        return true;
    };
    const nextStep = () => {
        if (!vaildeEmail()) return;
        setLoadingStatus(true);
        const pat = {
            email: state.email,
        };
        api.verifySubscribe(pat, res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                setPlaceholderText('Email address');
                const obj = Object.assign({ ...userInfo }, { ...state });
                dispatch(setUserInfo(obj));
                navigate(`/completeInformation`);
            } else if (code === 7000) {
                setPlaceholderText('Email has already been registered');
                setState({ email: '' });
                setIsError(true);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <div className="r-left login-main">
            <div className="login-box">
                <img src={Logo} className="logo_img" alt="" onClick={() => navigate('/webSite')} />
                <div className="login-form ptSign">
                    <h1>
                        CREATE FREE <br />
                        BETA ACCOUNT
                    </h1>
                    <p className="formTop_tip">
                        Create your free Nardo account to start designing and ordering your custom club apparel
                    </p>
                    <div className="baseSize" style={{ width: '100%' }}>
                        <Input
                            className={`r-input r-round-input`}
                            maxLength={150}
                            status={isError ? 'error' : ''}
                            value={state.email}
                            autoComplete="off"
                            onInput={(e: any) => setState({ ...state, email: e.target.value })}
                            placeholder={'Email address'}
                            // suffix={isError ? <i className="err-tip"></i> : <></>}
                        />
                        <p className="error-tip">{isError ? placeholderText : ''}</p>
                    </div>
                    <div className="baseSize">
                        <Button className="r-primary_btn" onClick={() => nextStep()}>
                            CONTINUE
                        </Button>
                    </div>
                    <p className="policy-tip">
                        By continuing, you agree to Nardo's{' '}
                        <a className="linkBox" target="_blank" href={`${process.env.REACT_APP_WEB_URI}/terms`}>
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a className="linkBox" target="_blank" href={`${process.env.REACT_APP_WEB_URI}/policy`}>
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
                <div className="top-clubsBox">
                    <div className="club_tip">Trusted by clubs at</div>
                    <div className="r-center">
                        {clubLists.map((item, i) => {
                            return (
                                <div
                                    className="clubImg_box"
                                    key={i}
                                    style={{ display: i > 3 && deviceType !== 'pc' ? 'none' : 'block' }}
                                >
                                    <img src={item} className="clubs_img" alt="" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <img src={SignImg} className="SignImg-right" alt="" />
        </div>
    );
};

export default SignUp;
