import React, { Suspense, useState, useEffect } from 'react';
import { HashRouter, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AxiosInterceptor } from './utils/index';
import auto from '@/utils/autofont';
import '@/assets/fonts/font.scss';
import routes from './router';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceType } from '@/store/stateSlice';
import { Spin } from 'antd';
import { hooks } from 'react-vant';
import useLoading from './hook/useLoading';
import { Loading3QuartersOutlined } from '@ant-design/icons';
const App = () => {
    const dispatch = useDispatch();
    const commonplace = useSelector((state: any) => state.commonplace);
    const [device, setDevice] = useState('pc');
    const [showPage, setShowPage] = useState(false);
    const { mainLoading, setLoadingStatus } = useLoading();
    const getDevice = () => {
        const clientWidth = document.documentElement.clientWidth;
        setDevice(clientWidth < 745 ? 'mb' : 'pc');
        dispatch(setDeviceType(clientWidth < 745 ? 'mb' : 'pc'));
        setTimeout(() => {
            setShowPage(true);
        }, 200);
    };
    const { width, height } = hooks.useWindowSize();
    useEffect(() => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${height}px`);
    }, [width, height]);
    window.onresize = function () {
        auto.autoFont();
        getDevice();
    };
    useEffect(() => {
        setLoadingStatus(false);
        auto.autoFont();
        getDevice();
    }, []);

    const basename = process.env.NODE_ENV === 'development' ? '/Nardo' : '/';
    return (
        <Router basename={basename}>
            <AxiosInterceptor>
                <Spin
                    spinning={mainLoading}
                    tip={''}
                    indicator={<Loading3QuartersOutlined spin style={{ fontSize: 40, color: '#DCFF00' }} />}
                    wrapperClassName={'fullLoading-box'}
                >
                    <Suspense>
                        <Routes>
                            {routes.map((route, index) => {
                                return (
                                    <Route
                                        path={route.path}
                                        key={index}
                                        element={
                                            <div className={`${device}-main ${showPage ? 'show-page' : 'hidden-page'}`}>
                                                <route.component routes={route.childrens ? route.childrens : []} />
                                            </div>
                                        }
                                    ></Route>
                                );
                            })}
                        </Routes>
                    </Suspense>
                </Spin>
            </AxiosInterceptor>
        </Router>
    );
};

export default App;
