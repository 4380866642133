//判断pc端还是移动端
const validPcOrPhone = (): string => {
    if (/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
        return 'phone';
    }
    return 'pc';
};
//设置根标签
const autoFont = () => {
    const fontBase = 100;
    const designWidth = 390;
    const html = document.querySelector('html') as HTMLElement;
    let currentWidth = 0;
    const clientWidth = document.documentElement.clientWidth;
    //判断是pc端，就设置固定宽度为750，可自行调整
    if (html.clientWidth > 750) {
        html.style.fontSize = '16px';
        return;
    } else {
        currentWidth = html.clientWidth;
    }
    const currentHeight = html.clientHeight;
    if (currentWidth > currentHeight) {
        currentWidth = currentHeight;
    }
    const currentFontWidth = fontBase * (currentWidth / designWidth);
    html.style.fontSize = currentFontWidth + 'px';
};
export default {
    autoFont,
};
