import './index.scss';
import LogoImg from '@/assets/images/website/logo.png';
// import Footer from '@/components/Footer/index';
import HomeFooter from '../home/components/home-footer';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <main className="not-found">
            <div className="logo">
                <img src={LogoImg} alt="404" />
            </div>
            <div className="not-found-content">
                <div className="error-tips">404 error</div>
                <div className="bold-tips">WE CAN'T FIND THAT PAGE</div>
                <div className="regular-tips">Sorry, the page you are looking for doesn't exist or has been moved.</div>

                <div className="not-found-btns">
                    <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                        GO BACK
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        TAKE ME HOME
                    </button>
                </div>
            </div>
            {/*  */}
            {/* <Footer /> */}
            <HomeFooter />
        </main>
    );
};

export default NotFound;
