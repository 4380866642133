import React from 'react';

import Index from '@/views/pages/index';
import Home from '@/views/pages/home';

import WebSite from '@/views/home/index';
import Policy from '@/views/home/policy';
import Teams from '@/views/home/team';
import SignUp from '@/views/login/signup';
import CompleteInformation from '@/views/login/completeInformation';
import JoinSuccess from '@/views/login/success';
import NotFound from '@/views/notFound';

export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
    icon?: any;
    key?: number;
}
const routes: RouteConfigDeclaration[] = [
    {
        path: '*',
        component: NotFound,
        exact: true,
        auth: false,
    },
    {
        path: '/',
        component: WebSite,
        exact: true,
        auth: false,
    },
    {
        path: '/webSite',
        component: WebSite,
        exact: true,
        auth: false,
    },
    {
        path: '/policy',
        component: Policy,
        exact: true,
        auth: false,
    },
    {
        path: '/terms',
        component: Teams,
        exact: true,
        auth: false,
    },
    {
        path: '/sign-up',
        component: SignUp,
        exact: true,
        auth: false,
    },
    {
        path: '/completeInformation',
        component: CompleteInformation,
        exact: true,
        auth: false,
    },
    {
        path: '/join-success',
        component: JoinSuccess,
        exact: true,
        auth: false,
    },
    // {
    //     path: '/*',
    //     component: Index,
    //     exact: false,
    //     childrens: [
    //         {
    //             name: 'Home',
    //             path: '/home',
    //             component: Home,
    //             exact: true,
    //             isShowOnNav: true,
    //             KeepAlive: false,
    //             icon: 'Home',
    //             key: 0,
    //         },
    //     ],
    // },
];

export default routes;
